// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-sensitive-teeth-js": () => import("./../../../src/pages/blog/sensitive-teeth.js" /* webpackChunkName: "component---src-pages-blog-sensitive-teeth-js" */),
  "component---src-pages-blog-seven-signs-teeth-js": () => import("./../../../src/pages/blog/seven-signs-teeth.js" /* webpackChunkName: "component---src-pages-blog-seven-signs-teeth-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-service-servicedetails-component-jsx": () => import("./../../../src/templates/service/servicedetails.component.jsx" /* webpackChunkName: "component---src-templates-service-servicedetails-component-jsx" */)
}

